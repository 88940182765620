import React from "react"
import loadable from "@loadable/component"

import { useCore } from "@app/hooks/useCore"
import Main from "@app/components/Main"

import type { PageProps } from "@root/types/global"

const Sections: React.FC<PageProps<any>> = ({ isHomepage = false, page }) => {
  const {
    helpers: { ErrorBoundary },
  } = useCore()

  return (
    <Main>
      <ErrorBoundary>
        {page?.content?.map((section: any, index: number) => {
          const headingIndex = index + (isHomepage ? 2 : 1)
          return (
            <DynamicComponent
              key={section._key?.toString()}
              section={section}
              index={index}
              tag={headingIndex > 6 ? "p" : `h${headingIndex}`}
            />
          )
        })}
      </ErrorBoundary>
    </Main>
  )
}

const DynamicComponent = ({ section, index, tag }: { section: any; index: number; tag: string }) => {
  const name = section?._type?.replace("section", "")
  try {
    const SectionComponent = loadable(() => import(`./${name}/${name}`))
    return <SectionComponent name={name} {...section} id={`section-${section?._key}`} index={index} tag={tag} />
  } catch (err) {
    // try to fix sanity sections misalignment
    console.error(err)
    return <></>
  }
}

export default Sections
