import React from "react"
import { PageProps, GetServerDataProps } from "gatsby"
import sanityClient from "@sanity/client"

import config from "../../config"
import Page from "@app/components/Sections/Sections"

export type Props = PageProps<GatsbyTypes.PageHomepageQuery, GatsbyTypes.PageHomepageQueryVariables>

const Component: React.FC<Props> = ({ data, ...props }) => <Page {...props} {...data} isHomepage page={props.serverData} />
export default Component

export async function getServerData(context: GetServerDataProps) {
  const { query } = context
  const isPreview = !!query?.preview

  try {
    const client = sanityClient({
      dataset: config.store.sanityDataset,
      projectId: config.store.sanityProjectId,
      apiVersion: config.store.sanityApiVersion,
      useCdn: isPreview ? false : true,
    })

    const baseQuery = `*[_type == "pageHomepage" && handle.current == coalesce(*[_type == "promotion" && handle.current == *[_type == "settingPromotions"][0].active][0].homepage->handle.current, "default") && %draftQuery%)]`
    const productionQuery = baseQuery.replace("%draftQuery%", "!(_id in path('drafts.**')")

    const objectCustomImage = `
      ...,
      asset-> {
        ...,
        metadata { dimensions }
      }
    `
    const objectImageResponsive = `..., mobile { ${objectCustomImage} }, desktop { ${objectCustomImage} }`
    const objectInternal = `..., document->`
    const wysiwygReferences = `
      ...,
      markDefs[] {
        ...,
        _type == "link" => {
          link { ..., ${objectInternal} },
        },
      },
    `

    const sectionQuery = `
      _id,
      metadata {
        title,
        description,
      },
      title,
      url,
      content[] {
        _type == "sectionReviews" || _type == "sectionInstagram" || _type == "sectionProductAddOns" || _type == "sectionRecentlyViewed" || _type == "sectionProductContents" => { ... },
        _type == "sectionHero" => {
          ...,
          image { ${objectImageResponsive} },
        },
        _type == "sectionFeaturedProducts" || _type == "sectionProductsCarousel" => {
          ...,
          products[]-> { shopify, },
        },
        _type == "sectionBanner" => {
          ...,
          image { ${objectCustomImage} },
        },
        _type == "sectionImagesTwoColumns" => {
          ...,
          columns[] {
            ...,
            link { ${objectInternal} },
            image { ${objectCustomImage} },
          },
        },
        _type == "sectionImageText" => {
          ...,
          image { ${objectCustomImage} },
        },
        _type == "sectionBrandCarousel" => {
          ...,
          brands[] {
            ...,
            image { ${objectCustomImage} },
          },
        },
        _type == "sectionText" => {
          ...,
          link { ${objectInternal} },
        },
        _type == "sectionTextButton" => {
          ...,
          content[] { ${wysiwygReferences} },
          button { ${objectInternal} },
          link { ${objectInternal} },
        },
        _type == "sectionContentTiles" => {
          ...,
          tiles[] {
            ...,
            image { ${objectCustomImage} },
          },
        },
        _type == "sectionProductAccordion" => {
          ...,
          accordionBlocks[] {
            ...,
            content[] { ${wysiwygReferences} },
          },
        },
      }
    `

    if (isPreview) {
      const previewQuery = baseQuery.replace("%draftQuery%", "_id in path('drafts.**')")
      const previewResponse = await client.fetch(
        `${previewQuery}[0] {
          ${sectionQuery}
        }`
      )

      if (previewResponse !== null) {
        return {
          status: 200,
          props: previewResponse,
        }
      }
    }

    const response = await client.fetch(
      `
        ${productionQuery}[0] {
          ${sectionQuery}
        }
      `
    )
    return {
      status: 200,
      props: response,
    }
  } catch (error) {
    console.error((error as Error).message)

    return {
      status: 500,
    }
  }
}
